<template>
  <section>
    <timeline-sidebar
      :is-timeline-sidebar-active.sync="isTimelineSidebarActive"
      @get-timeline="getTimeline"
    />  

    <b-card title="时间线">
      <b-row align-h="end">
        <b-col>
          <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="搜索时间线内容..."
            />
        </b-col>
        <b-col>
          <b-button
            class="mb-1"
            variant="primary"
            @click="isTimelineSidebarActive = true"
          >新增
          </b-button>
        </b-col>
      </b-row>
      <app-timeline style="max-height:400px; overflow-y:scroll">

        <app-timeline-item v-for="i in filteredTimelineData" :key="i.user_id">
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h5>{{ i.user_name }}:</h5>
            <small class="text-muted">{{ i.time_cn }}</small>
          </div>

          <b-badge 
          class="mr-1"
            v-if="i.type == 'multi'"
            v-for = "action in i.edit_contents"
            :key="action"
            variant="light-info">
            {{ action }}
          </b-badge>

          <b-badge 
            v-if="i.type == 'single'"
            variant="light-info">
            {{ i.content }}
          </b-badge>

          <p v-else-if="i.type == 'input'"
          >{{ i.content }}</p>
          <a 
            class="text-primary"
            @click="loadFile(i.file_id,i.file_name)">
            <font-awesome-icon
              v-if="i.file_name"
              icon="fa-solid fa-paperclip"
            />
            {{ i.file_name }}
          </a>
        </app-timeline-item>
      
      </app-timeline>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,BButton,BCardBody,BRow,BCol,BFormInput,BBadge
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import TimelineSidebar from './TimelineSidebar.vue'

export default {
  components: {
    BBadge,
    BCard,
    BImg,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BAvatar,
    BCardBody,
    BButton,
    AppTimeline,
    AppTimelineItem,
    TimelineSidebar
  },
  props: {
    timelineData: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
      fileDownloading:false,
      isTimelineSidebarActive: false,
      searchQuery: ''
    }
  },
  computed:{
    filteredTimelineData(){
      return this.timelineData.filter((i) => {
        return i.content.includes(this.searchQuery)
      })
    }
  },
  methods:{
    getTimeline(){
      this.$emit('get-timeline')
    },
    loadFile(fileId,fileName){
      this.$axios.get("/crm/contacts/timeline/get_file/" + fileId).then(res=>{        
        const url = this.$backend + '/download_by_token/' + res.data.data.token
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', fileName )
        document.body.appendChild(link)
        link.click()
        link.remove() 
      })
    }
  }
}
</script>