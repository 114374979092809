<template>
  <b-card>
    <div class="d-flex justify-content-start">

      <div class="d-flex flex-column ml-1">
        <div class="mb-1">
          <h4 class="mb-0">
            {{ contactData.last_name }} {{ contactData.first_name }}
            <a  href="#" @click.prevent="$emit('toggle-starred')"><feather-icon
              icon="StarIcon"
              size="17"
              :class="{ 'text-warning fill-current': contactData.starred }"
            /></a>
          </h4>

        </div>
        <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$emit('edit-contact')"
          >
            编辑
          </b-button>
          <b-button
            variant="outline-danger"
            class="ml-1"
            @click="delConfirm"
          >
            删除联系人
          </b-button>
        </div>
      </div>
    </div>

    <b-card-body>
    <table class="mt-2 mt-xl-0 w-100">
      <tr>
        <th class="pb-50">
          <font-awesome-icon
            icon="fa-solid fa-envelope"
            size="lg"
            class="mr-75"/>
          <span class="font-weight-bold">邮箱</span>
        </th>
        <td class="pb-50">
          {{ contactData.email }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <font-awesome-icon
            icon="fa-solid fa-mars-and-venus"
            size="lg"
            class="mr-75"/>
          <span class="font-weight-bold">性别</span>
        </th>
        <td class="pb-50 text-capitalize">
          {{ contactData.gender_cn }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <font-awesome-icon
            icon="fa-solid fa-home"
            size="lg"
            class="mr-75"/>
          <span class="font-weight-bold">公司</span>
        </th>
        <td class="pb-50 text-capitalize">
          {{ contactData.company_name }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <font-awesome-icon
            icon="fa-solid fa-user-tie"
            size="lg"
            class="mr-75"/>
          <span class="font-weight-bold">职位</span>
        </th>
        <td class="pb-50">
          {{ contactData.title }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <font-awesome-icon
            icon="fa-solid fa-phone"
            size="lg"
            class="mr-75"/>
          <span class="font-weight-bold">电话</span>
        </th>
        <td class="pb-50">
          {{ contactData.phone }}
        </td>
      </tr>
      <tr>
        <th class="pb-50">
          <font-awesome-icon
            icon="fa-solid fa-face-smile"
            size="lg"
            class="mr-75"/>
          <span class="font-weight-bold">称谓</span>
        </th>
        <td>
          {{ contactData.appellation }}
        </td>
      </tr>

      <tr>
        <th>
          <font-awesome-icon
            icon="fa-solid fa-inbox"
            size="lg"
            class="mr-75"/>
          <span class="font-weight-bold">收件地址</span>
        </th>
        <td>
          {{ contactData.receive_address }}
        </td>
      </tr>
      
    </table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,BCardBody,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BCardBody
  },
  directives: {
    Ripple,
  },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  methods:{
    delContact(){
      this.$axios.get("/crm/contacts/system/del_contact/" + this.$route.params.id ).then((response) => {
        this.$router.go(-1)
      })
    },
    delConfirm() {
      this.$swal({
        title: '删除联系人',
        text: "删除后无法撤销，你确定要删除该联系人吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delContact()
        }
      })
    }
  },
}
</script>
