<template>
  <b-card v-if="wechatId">
    <b-card-title>企业微信</b-card-title>
    <b-badge variant="light-warning" v-if="wxContactInfo.unconnect">您未添加过该微信联系人</b-badge>
    <b-card-sub-title v-if="!this.wechatId">未绑定微信联系人</b-card-sub-title>
    <b-card-body>
    <b-row v-if="this.wechatId">
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="wxContactInfo.external_contact.avatar"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
               {{ wxContactInfo.follow_info.remark }} 
              </h4>
              <span class="card-text">@{{ wxContactInfo.external_contact.name }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="unbind"
              >
                解绑
              </b-button>

            </div>
          </div>
        </div>
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100 text-nowrap">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user-tag"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">标签</span>
            </th>
            <td class="pb-50">
              <b-badge
               v-for="tag in wxContactInfo.follow_info.tags"
                :key="tag.tag_id"
                variant="light-primary"
                class="mr-1"
                pill
                >
                {{tag.tag_name}}
              </b-badge>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-quote-right"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">描述</span>
            </th>
            <td class="pb-50" >
            {{  wxContactInfo.follow_info.description }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-house"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">公司</span>
            </th>
            <td class="pb-50" >
              <b-badge variant="light-warning" v-if="wxContactInfo.external_contact.corp_full_name">
                <font-awesome-icon
                  icon="fa-brands fa-weixin"
                />
                {{ wxContactInfo.external_contact.corp_full_name }}
              </b-badge>
              <template v-else>
                {{  wxContactInfo.follow_info.remark_corp_name }}
              </template>
            </td>

          </tr>
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-phone"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">电话</span>
            </th>
            <td class="pb-50" v-for="phone in wxContactInfo.follow_info.remark_mobiles">
            {{ phone }}
            </td>
          </tr>
         
        </table>
      </b-col>
    </b-row>
    </b-card-body>
    </b-card>

    <b-card v-else>
    <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="搜索微信联系人..."
            />
      <b-card class="border mb-0 mt-1" style="max-height:200px;overflow:scroll">
        <div 
          v-if="!wxLoaded"
          class="text-center">
            <b-spinner
              variant="primary"
              class="mr-1"
              type="grow"
            />
        </div>
        <div
        v-else
        v-for="i in filterWxContacts"
        :key="i.external_contact.external_userid"
        class="employee-task d-flex justify-content-between align-items-center mt-1"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-avatar
              rounded
              size="42"
              :src="i.external_contact.avatar"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">
              {{ i.follow_info.remark }}
            </h6>
            <small>{{ i.external_contact.name }}</small>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            @click="bindWxContact(i.external_contact.external_userid)"
          >
            绑定
          </b-button>
        </div>
      </div>
  </b-card>
    </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return{
      searchQuery: '',
    }
  },
  computed:{
    filterWxContacts(){
      return this.wxContacts.filter((i) => {
        return i.follow_info.remark.includes(this.searchQuery)
        || i.external_contact.name.includes(this.searchQuery)
      })
    }
  },
  props:{
    wechatId: {
      type: String,
    },
    wxLoaded: {
      type: Boolean,
      default: false
    },
    wxContacts: {
      type: Array,
    },
    wxContactInfo:{
      type: Object,
      default: () => {
        return {
          external_contact: {
            avatar: "",
          },
          follow_info:{
            remark:"",
          }
        }
      }
    },
  },
  methods:{
    bindWxContact: function(id){
      this.$axios.post("/crm/contacts/system/bind_wx", {
        contactId: this.$route.params.id,
        wechatId: id
      }).then((response) => {
        if (response.data.status == "ok"){
          this.$emit("get-contact")
        }
      })
    },
    unbind(){
      this.$axios.get("/crm/contacts/system/unbind_wx/" + this.$route.params.id).then((response) => {
        if (response.data.status == "ok"){
          this.$emit("get-contact")
        }
      })
    },
  },
    
}
</script>